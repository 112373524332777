import { useState } from "react";
import PropTypes from "prop-types";
import Toggle from "./Toggle";
import { ReactComponent as DownIcon } from "../images/down.svg";
import DataSelector from "./DataSelector";
import MultiplexedDataSelector from "./MultiplexedDataSelector";
import Selector from "./Selector";
import CsvField from "./CsvField";
import { makeFormRows } from "../utils";
import PipelineSectionFiller from "./PipelineSectionFiller";
import Checkbox from "./Checkbox";

const PipelineInputsSection = props => {

  const {
    name, section, openSections, setOpenSections, organism, setOrganism,
    params, setParams, dataParams, setDataParams, csvParams, setCsvParams,
    filesets, filesetId, setFilesetId, upstreamPipelineVersions,
    resequenceSamples, setResequenceSamples,
    modes, setModes,
  } = props;

  const [lockedKeys, setLockedKeys] = useState([]);
  const [executionPrep, setExecutionPrep] = useState(null);
  const [keepAsCsvs, setKeepAsCsvs] = useState([]);
  const [forceShowFilesetInputs, setForceShowFilesetInputs] = useState(false);
  const mode = modes[name];
  const displayMode = mode || (section.modes && section.modes[0]);

  const isDemultiplexSection = Object.values(section.params).some(p => p.category === 2) && Object.values(section.params).some(p => p.type === "lane");

  const inputClass = "bg-flow-gray-1 border placeholder-text-[#777F9B] border-flow-gray-5 rounded mb-1.5 w-full py-1.5 min-h-[36px] text-flow-blue-2 font-medium px-3 text-sm md:text-base";
  const selectOptionClass = "h-8 px-3 flex items-center cursor-pointer text-flow-blue-2 hover:bg-gray-100";
  const selectOptionsClass = "bg-flow-gray-1 border-t border-t-gray-200 rounded-b border-x border-b border-flow-gray-5 mb-1.5 -mt-2 w-full cursor-pointer text-flow-blue-7 font-medium";
  const placeholderClass = "text-flow-gray-5 font-normal";

  const updateParam = (key, value, hasDefault) => {
    if (value || hasDefault) {
      setParams({...params, [key]: value});
    } else {
      const {[key]: _, ...rest} = params;
      setParams(rest);
    }
  };

  const toggleVisibility = name => {
    if (openSections.includes(name)) {
      setOpenSections(openSections.filter(n => n !== name));
    } else {
      setOpenSections([...openSections, name]);
    }
  }

  const isOpen = openSections.includes(name);

  const missing = Object.entries(section.params).filter(
    ([k, i]) => i.required && !params[k] && (
      dataParams[k]?.paired === undefined ? !dataParams[k] : dataParams[k]?.data?.length === 0
    ) && (!csvParams[k]) && !i.default
  ).length;

  const rows = makeFormRows(section, displayMode);

  const modeChanged = mode => {
    for (const [key, value] of Object.entries(section.params)) {
      const modes = section.params[value.param || key].modes;
      if (modes.includes(displayMode) && !modes.includes(mode)) {
        if (params[value.param || key]) {
          delete params[value.param || key];
          setParams({...params});
        }
        if (dataParams[value.param || key]) {
          delete dataParams[value.param || key];
          setDataParams({...dataParams});
        }
      }
    }
    setModes({...modes, [name]: mode});
  }

  return (
    <div
      className={`transition-all max-w-7xl ${isOpen ? "px-0 py-1" : "hover:bg-[#F4F4F4] bg-[#F8F8F8] cursor-pointer rounded px-3 py-2"} ${props.className || ""}`}
      onClick={isOpen ? undefined : () => toggleVisibility(name)}
    >
      <div className={`cursor-pointer  ${isOpen ? "" : "border-l-4 border-flow-blue-7 pl-2"}`} onClick={isOpen ? () => toggleVisibility(name) : undefined}>
        <div className="font-medium text-lg text-flow-blue-7 w-fit">
          {section.name} <DownIcon className={`w-4 h-auto fill-flow-blue-7 transform-all duration-200 inline ${!isOpen ? "-rotate-90" : ""}`} />
        </div>
        <div className="text-sm">{section.description}</div>
        {!isOpen && (
          <div className="text-xs mt-1 font-medium">
            {missing === 0 ? (
              <span className="text-flow-gray-9">{Object.keys(section.params).length} input{Object.keys(section.params).length === 1 ? "" : "s"}</span>
            ) : (
              <span className="text-red-700"> {missing} required input{missing === 1 ? "" : "s"} still blank</span>
            )}
          </div> 
        )}
      </div>
      {isOpen && (
        <div className="flex flex-col gap-8 mt-5 transition-all duration-500 -mb-2">
          {section.modes && (
            <div>
              <div className="text-[#515151] font-medium text-base">Mode</div>
              <Selector
                value={displayMode}
                options={section.modes.map(m => ({id: m, label: m}))}
                onChange={modeChanged}
                inputClassName={`${inputClass} cursor-pointer max-w-xs`}
                optionClassName={`${selectOptionClass}`}
                optionsClassName={`${selectOptionsClass} max-w-xs`}
              >
                {section.modes.map((m, i) => (
                  <option key={i} value={m} selected={m === mode}>{m}</option>
                ))}
              </Selector>
            </div>
          )}
          <PipelineSectionFiller
            section={section}
            filesets={filesets} organism={organism} setOrganism={setOrganism}
            upstreamPipelineVersions={upstreamPipelineVersions}
            filesetId={filesetId} setFilesetId={setFilesetId}
            executionPrep={executionPrep} setExecutionPrep={setExecutionPrep}
            dataParams={dataParams} setDataParams={setDataParams}
            lockedKeys={lockedKeys} setLockedKeys={setLockedKeys}
            inputClass={inputClass} placeholderClass={placeholderClass}
            selectOptionClass={selectOptionClass}
            selectOptionsClass={selectOptionsClass}
          />
          {section.from_execution && !forceShowFilesetInputs && !executionPrep && (
            <div
              onClick={(() => setForceShowFilesetInputs(true))}
              className="text-flow-blue-7 text-sm -mt-4 mb-1 cursor-pointer"
            >
              Manually select files
            </div>
          )}
          {(!section.from_execution || forceShowFilesetInputs || executionPrep) && rows.map((row, i) => (
            <div key={i} className={`${["data", "csv"].includes(row[0][1].type) ? "" : "grid"} gap-x-8 gap-y-8 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4`}>
              {row.map(([key, input]) => (
                <div key={key} className="max-w-3xl w-full">
                  <div className="flex items-center gap-2 mb-1">
                    {input.required && <div className="bg-flow-blue-7 text-white text-xs rounded px-1 pt-px pb-0.5">required</div>}
                    <div className="text-[#515151] font-medium text-lg">{input.name}</div>
                  </div>
    
                  {input.type === "boolean" && (
                    <Toggle
                      value={((params[key]) || input.default) === "true"} className="mb-2"
                      trueLabel="True" falseLabel="False"
                      onChange={value => setParams({...params, [input.param || key]: value.toString()})}
                    />
                  )}
                  {input.type === "data" && (
                    <DataSelector
                      inputClass={inputClass}
                      data={dataParams[input.param || key]}
                      setData={data => setDataParams({...dataParams, [input.param || key]: data})}
                      pattern={input.pattern}
                      category={input.category}
                      placeholder="Select a file"
                      locked={lockedKeys.includes(key)}
                      placeholderClass={placeholderClass}
                    />
                  )}
                  {input.type === "csv" && (
                    <div>
                      {process.env.REACT_APP_ALLOW_DATA_CSVS && (
                        <div className="flex items-center gap-2 mb-1">
                          <Checkbox
                            checked={!keepAsCsvs.includes(key)}
                            onChange={value => setKeepAsCsvs(value ? keepAsCsvs.filter(k => k !== key) : [...keepAsCsvs, key])}
                            />
                            <div>Use manually created CSV?</div>
                        </div> 
                      )}
                      {(!process.env.REACT_APP_ALLOW_DATA_CSVS || keepAsCsvs.includes(key)) ? (
                        <CsvField
                          schema={input}
                          data={csvParams[input.param || key] || {rows: [], paired: "both", mode: displayMode}}
                          setData={data => setCsvParams({...csvParams, [input.param || key]: data})}
                          organism={organism}
                          setOrganism={setOrganism}
                          inputClass={inputClass}
                          placeholderClass={placeholderClass}
                          selectOptionClass={selectOptionClass}
                          selectOptionsClass={selectOptionsClass}
                        />
                      ) : (
                        <DataSelector
                          inputClass={inputClass}
                          data={dataParams[input.param || key]}
                          setData={data => setDataParams({...dataParams, [input.param || key]: data})}
                          pattern="csv$"
                          placeholder="Select a file"
                          locked={lockedKeys.includes(key)}
                          placeholderClass={placeholderClass}
                        />
                      )}
                    </div>
                  )}
                  {input.type === "lane" && (
                    <MultiplexedDataSelector
                      inputClass={inputClass}
                      placeholderClass={placeholderClass}
                      data={dataParams[key]}
                      setData={data => setDataParams({...dataParams, [input.param || key]: data})}
                    />
                  )}
                  
                  {input.valid && (
                    <Selector
                      value={(params[input.param || key] === undefined && input.default) ? input.default.toString() : params[input.param || key] || ""}
                      options={[{id: "", label: ""}, ...input.valid.map(v => ({id: v, label: v}))]}
                      onChange={value => setParams({...params, [input.param || key]: value})}
                      inputClassName={`${inputClass} cursor-pointer`}
                      placeholder="Select an option"
                      optionClassName={selectOptionClass}
                      optionsClassName={selectOptionsClass}
                    /> 
                  )}
                  {!["boolean", "data", "hidden", "csv"].includes(input.type) && !input.valid && (
                    <input
                      className={inputClass}
                      type={input.type === "number" ? "number" : "text"}
                      step={input.type === "number" ? 0.01 : undefined}
                      value={(params[input.param || key] === undefined && input.default) ? input.default.toString() : (params[input.param || key] || "")}
                      onChange={e => updateParam(input.param || key, e.target.value, Boolean(input.default))}
                    />
                  )}
    
                  <div className="text-sm text-[#565656] break-words">
                    {input.description}
                  </div>
                </div>
              ))}
            </div>
          ))}
          {isDemultiplexSection && (
            <div className="max-w-3xl">
              <div className="flex items-center gap-2 mb-1">
                <div className="text-[#515151] font-medium text-lg">Resequence Samples</div>
              </div>
              <Toggle
                value={!resequenceSamples} className="mb-2"
                trueLabel="New samples" falseLabel="Append to existing"
                onChange={value => setResequenceSamples(!value)}
              />
              <div className="text-sm text-[#565656] break-words">
                By default, Flow will always make new samples from the rows in the annotation sheet. If you prefer, you can append the new data to any existing samples you own with the same name.
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

PipelineInputsSection.propTypes = {
  name: PropTypes.string.isRequired,
  section: PropTypes.object.isRequired,
  openSections: PropTypes.array.isRequired,
  setOpenSections: PropTypes.func.isRequired,
  organism: PropTypes.object,
  setOrganism: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  setParams: PropTypes.func.isRequired,
  dataParams: PropTypes.object.isRequired,
  setDataParams: PropTypes.func.isRequired,
  sampleParams: PropTypes.object.isRequired,
  setSampleParams: PropTypes.func.isRequired,
  filesets: PropTypes.array,
  filesetId: PropTypes.string,
  setFilesetId: PropTypes.func.isRequired,
  upstreamPipelineVersions: PropTypes.array,
  resequenceSamples: PropTypes.bool.isRequired,
  setResequenceSamples: PropTypes.func.isRequired,
};

export default PipelineInputsSection;